import { Box, FileItem, IconButtonSubmit, useTranslations } from "@mzara/component";
import { useParams } from "react-router-dom";
import { ContractWarningControl } from "../components/ContractWarningControl";
import { ContractPVControl } from "../components/ContractPVControl";
import { useDownloadWarningFile } from "../hooks/useDownloadWarningFile";
import { useDownloadPVFile } from "../hooks/useDownloadPVFile";

export const DetailContractWarningsContainer = () => {
    const { id } = useParams();
    const [TITLE, WARNING_TEMPLATE, PV_TEMPLATE] = useTranslations(i18n);
    const downloadWarningFile = useDownloadWarningFile();
    const downloadPVFile = useDownloadPVFile();

    return (
        <div className="flex flex-col gap-4">
            <ContractWarningControl id={parseInt(id)} />

            <ContractPVControl id={parseInt(id)} />

            <Box title={TITLE}>
                <div className="divide-y divide-gray-light">
                    <div className="py-2 flex gap-4">
                        <FileItem
                            name={WARNING_TEMPLATE}
                            endIcon={
                                <IconButtonSubmit
                                    icon={"fa-download"}
                                    isSubmit={downloadWarningFile.isLoading}
                                    onClick={() =>
                                        downloadWarningFile.mutate(parseInt(id))
                                    }
                                />
                            }
                        />
                        <FileItem
                            name={PV_TEMPLATE}
                            endIcon={
                                <IconButtonSubmit
                                    icon={"fa-download"}
                                    isSubmit={downloadPVFile.isLoading}
                                    onClick={() =>
                                        downloadPVFile.mutate(parseInt(id))
                                    }
                                />
                            }
                        />
                    </div>
                </div>
            </Box>
        </div>
    );
};

const i18n = [
    "Harea.Contract.Details.About.Confirmed.WarningBox.Templates.Title",
    "Harea.Contract.Details.Warning.Document.Template.Title",
    "Harea.Contract.Details.PV.Document.Template.Title",
];
