import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractDetailHistoryContainer = React.lazy(
    () => import("./container/ContractDetailHistoryContainer")
);
export const ContractDetailHistoryRoute = [
    {
        path: "history",
        element: <ContractDetailHistoryContainer />,
        roles: ["PAYING_SLIP"],
    },
] as Array<AppRouteProps>;
