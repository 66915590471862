import { useTranslationRoute } from "@mzara/component"
import { useAppointmentEventUsersQuery } from "./useAppointmentEventUsersQuery"
import { TiptapMentionConfigProps } from "@mzara/component/dist/components/control/components/TiptapMentionConfig"

export const useAppointmentEventTiptapSuggestions = (): Array<TiptapMentionConfigProps> => {

    const { data: users } = useAppointmentEventUsersQuery(true)
    const tr = useTranslationRoute()

    return [{
        name: "user-mention",
        renderHTML({ options, node,  }) {
            console.log('user-mention', options)
            return [
                'a',
                { ...options.HTMLAttributes, href: tr(`admin/user/${node.attrs.id}`) },
                `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
            ]
        },
        options: users.map((item) => ({ id: item.id, label: item.fullName, userId: item.id }))
    }]
}

