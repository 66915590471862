import { GenericSuspense, MainLogo, Menu, useSetCurrentDocumentTitle, useTranslations } from "@mzara/component"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useKanbanMilestoneQuery } from "../hooks/useKanbanMilestoneQuery"
import { useKanbanMilestoneMenu } from "../hooks/useKanbanMilestoneMenu"
import { useKanbanMilestoneTab } from "../hooks/useKanbanMilestoneTab"
import { useEffect } from "react"

const ProjectKanbanDetailsMilestoneContainer = () => {

    const { milestoneId } = useParams()
    const { data } = useKanbanMilestoneQuery(parseInt(milestoneId), true)
    useSetCurrentDocumentTitle('AppointmentBoardMilestoneTitle', data.title)
    const menus = useKanbanMilestoneMenu({ milestoneId: parseInt(milestoneId) })
    const tab = useKanbanMilestoneTab()
    const navigate = useNavigate()

    useEffect(() => {
        if (!menus.some((item) => item.ke === tab)) {
            navigate(menus[0].link, { replace: true })
        }
    }, [tab])

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between">
                <h3>{data?.title}</h3>
                {/** Add menu here */}
            </div>
            <Menu
                type="HORIZONTAL"
                className='menu-tabs'
                items={menus}
                activeFn={(menu) => menu.ke === tab}
            />
            <div>
                <GenericSuspense fallback={<MainLogo />}>
                    <Outlet />
                </GenericSuspense>
            </div>
        </div>
    )
}

const i18n = [
    'Harea.Project.Kanban.Details.Gantt.DropTicket.Dialog.Title'
]

export default ProjectKanbanDetailsMilestoneContainer
