import { Chip, useTranslations } from "@mzara/component"
import { TAppointmentMilestone } from "@mzara/graphql"

export const MilestoneState = (props: Props) => {

    const [
        NEW,
        IN_PROGRESS,
        COMPLETE,
        CLOSED,
        ARCHIVED
    ] = useTranslations(i18n)

    if (props.dateClosed as any) {
        return (
            <Chip label={CLOSED} className="bg-danger text-white" />
        )
    }

    if (props.dateArchive as any) {
        return (
            <Chip label={ARCHIVED} className="bg-default" />
        )
    }

    if (props.ticketsCount as any === 0) {
        return (
            <Chip label={NEW} className="bg-link text-white" />
        )
    }

    if (props.openTicketsCount as any === 0) {
        return (
            <Chip label={COMPLETE} className="bg-success text-white" />
        )
    }


    return (
        <Chip label={IN_PROGRESS} className="bg-primary text-white" />
    )
}

const i18n = [
    'std_new',
    'std_in_progress',
    'std_complete',
    'std_closed',
    'std_archived'
]

type Props = Partial<TAppointmentMilestone> & {

}
