
import { MenuItemProps, useTranslations } from "@mzara/component"
import { useMemo } from "react"
import { useKanbanMilestoneQuery } from "../../../hooks/useKanbanMilestoneQuery"

export const useKanbanMilestonMenuItems = (milestoneId: number) => {

    const [
        COPY_LINK,
        ARCHIVE,
        DELETE,
        CLOSE,
        OPEN,
        UN_ARCHIVE,
    ] = useTranslations(i18n)
    const { data } = useKanbanMilestoneQuery(milestoneId, true)

    return useMemo((): Array<MenuItemProps> => ([
        {
            ke: 'copy-link',
            label: COPY_LINK,
        },
        {
            divide:true
        },
        data.dateClosed && !data.dateArchive ? {
            ke: 'open',
            label: OPEN,
        } : null,
        !data.dateClosed && !data.dateArchive ? {
            ke: 'close',
            label: CLOSE,
        } : null,
        !data.dateArchive ? {
            ke: 'archive',
            label: ARCHIVE,
        } : null,
        data.dateArchive ? {
            ke: 'unarchive',
            label: UN_ARCHIVE,
        } : null,
    ]), [COPY_LINK, data])
}

const i18n = [
    "std_copy_link",
    "std_archive",
    "std_delete",
    'std_close',
    'std_open',
    'std_unarchive'
]

