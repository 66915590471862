import { useGraphqlMutation, useTranslation } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";

export const useKanbanMilestoneGraphqlMutation = () => {
    const t = useTranslation()
    return useGraphqlMutation<TAppointment>(gql, { showLoadingMessage: true, loadingMessage: t('std_saving') });
};

const gql = `
    mutation AppointmentMilestoneGraphqlMutation($data: JSONObject, $datas: [JSONObject!]) {
        saveAppointmentMilestone(data: { data: $data, datas: $datas }) {
            id
        }
    }
`;
