import { ControlListFormItem, useRuntimeEnv, useTranslations } from "@mzara/component";
import { TFile } from "@mzara/graphql";
import { useMemo } from "react";

export const useContractWarningFilesForm = () => {
    const [
        TITLE,
        WARNING,
        TOOLTIP_TEXT_WARNING,
        CREATE
    ] = useTranslations(i18n);


    return useMemo(() => {
        return {

            id: "contract-detail-confirmed-dismissal-form-id",
            "data-testid": "contract-detail-confirmed-dismissal-form-info",
            data: {
                title: TITLE,
                forms: [
                    {
                        type: "hidden",
                        name: "id",
                    },
                    {
                        type: "drop-zone",
                        label: WARNING,
                        name: "warningFiles",
                        valueObject: true,
                        valueProperty: "id",
                        required: true,
                    },
        
                ],
                graphQL: {
                    entity: "contract",

                },
                next: {
                    label: CREATE,
                    "data-testid": "contract-detail-confirmed-dismissal-form-info",
                },
            }
        };
    }, []);
};


const i18n = [
    "Harea.Contract.Template.Details.Parameter.Title.Information",
    "Harea.Contract.Details.About.Confirmed.Dismissal.NormalFault.Warning",
    "Harea.Contract.Details.About.Confirmed.Dismissal.Tooltip.Text.Warning",
    'std_add'
];
