import { Button, Dropdown, Menu, MenuItemProps, useFileUrl, useRuntimeEnv, useTranslationRoute, useTranslations } from "@mzara/component"
import { useItemsUserOrganisations } from "components/layout/hooks/useDataUserOrganisations";
import { useUserOrganisationMutation } from "components/layout/hooks/useUserOrganisationMutation";
import { useCallback, useState } from "react";

export const OrganisationMenu = () => {

    const [
        ORGANISATION_LABEL,
        ORGANISATION_CREATE_LABEL,
        PERSONNAL_ACCOUNT_LABEL,
    ] = useTranslations(i18n)
    const items = useItemsUserOrganisations();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const tr = useTranslationRoute()
    const userOrganisationMutation = useUserOrganisationMutation();
    const { runtimeEnv } = useRuntimeEnv()

    const handleClick = useCallback(
        (item: MenuItemProps) => {
            if (item.ke === "account") {
                userOrganisationMutation.mutate(
                    { id: null },
                    {
                        onSuccess: () => {
                            window.location.reload();
                        },
                        onError: () => console.log("error"),
                    }
                );

                return;
            }

            if (typeof item.ke === "string") {
                userOrganisationMutation.mutate(
                    {
                        id: parseInt(item.ke),
                    },
                    {
                        onSuccess: () => {
                            window.location.reload();
                        },
                        onError: () => console.log("error"),
                    }
                );
            }


        },
        [userOrganisationMutation]
    );

    return (
        <div>
            <Button
                className="btn btn-primary"
                label={
                    !runtimeEnv?.organisation ? ORGANISATION_LABEL : runtimeEnv?.organisation?.designation
                }
                onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Dropdown
                anchorEl={anchorEl}
                arrow
                placement="bottom-end"
                onClose={() => setAnchorEl(undefined)}
            >
                <Menu
                    name="organisation-menu"
                    items={[
                        ...(items.map((item) => ({
                            ...item,
                            link: runtimeEnv?.organisation?.id === item.id
                                ? tr(`admin/user/organisation/${runtimeEnv?.organisation.id}`)
                                : undefined,

                        }))),
                        {
                            divide: true,
                        },
                        !runtimeEnv?.organisation
                            ? {
                                link: tr('organisation/create'),
                                label: ORGANISATION_CREATE_LABEL,
                            }
                            : {
                                ke: 'account',
                                startIcon: 'fa-solid fa-user',
                                label: PERSONNAL_ACCOUNT_LABEL
                            }
                    ]}
                    activeFn={(item) => runtimeEnv?.organisation?.id === item.id}
                    onClick={handleClick}
                />
            </Dropdown>
        </div>
    )
}

const i18n = [
    'Harea.Client.Navbar.Menu.Organisation.Label',
    'Harea.Client.Navbar.Menu.Organisation.Create.Label',
    'Harea.Admin.Navbar.Menu.Organisation.PersonnalAccount.Label',
]
