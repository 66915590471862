import { ControlList } from "@mzara/component"
import { Thread } from "@mzara/thread"
import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useAddKanbanCardForm } from "../../../../../../../hooks/useAddKanbanCardForm"
import { useCalendarBeforeSave } from "views/calendar/hooks/useCalendarBeforeSave"
import { useAppointmentBoardQuery } from "../../../../../../../hooks/useAppointmentBoardQuery"
import { useKanbanCardDetailsQuery } from "../../../../../hooks/useKanbanCardDetailsQuery"
import { useQueryClient } from "react-query"
import { useKanbanBoardWebsocketSync } from "views/kanban/views/details/views/board/hooks/useKanbanBoardWebsocketSync"

export const ProjectKanbanEditAppointment = (props: Props) => {


    const { id, cardNum } = useParams()
    const { data, invalidateQuery: invalidateAppointmentOwnerQuery } = useKanbanCardDetailsQuery(parseInt(id), parseInt(cardNum), true);
    const { invalidateQuery } = useAppointmentBoardQuery(parseInt(id))
    const control = useAddKanbanCardForm();
    const handleBeforeSave = useCalendarBeforeSave()
    const queryClient = useQueryClient()
    const dispatchEvent = useKanbanBoardWebsocketSync(parseInt(id))


    const handleSubmited = useCallback(() => {
        invalidateQuery()
        invalidateAppointmentOwnerQuery()
        queryClient.invalidateQueries('App.Harea.Appointments.Kanban.History.Query')
        dispatchEvent()
        props?.onClose?.()
    }, [props])

    return (
        <ControlList
            {...control}
            defaultValue={{
                ...data,
                board: undefined,
                invitations: data?.invitations?.map((item) => item.user?.id || item.email),
                milestone: data.milestone?.id
            }}
            onCancel={props?.onClose}
            onSubmited={handleSubmited}
            onBeforeSaving={handleBeforeSave}
        />
    )
}

type Props = {
    onClose?: () => void
}
