import { useGraphqlQuery } from "@mzara/component";
import { OrganisationWorkHourQuery } from "../queries/OrganisationWorkHourQuery";
import { TUserOrganisation, TUserOrganisationProfile } from "@mzara/graphql";

export const useOrganisationWorkHourQuery = (
    id: number,
    suspense?: boolean
) => {
    return useGraphqlQuery<OrganisationWorkHourQuery, TUserOrganisationProfile>(
        new OrganisationWorkHourQuery({ id }),
        { suspense, enabled: !!id }
    );
};
