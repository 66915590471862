import { Box, Chip, DateSpan, Metadata, Tooltip, useTranslations, useUrlParamsValueToString } from "@mzara/component"
import { TAppointmentMilestone } from "@mzara/graphql"
import { MilestoneState } from "./MilestoneState"
import { Link } from "react-router-dom"
import _ from "lodash"

export const MilestoneRowItem = (props: Props) => {

    const [
        DATE_BEGIN,
        DATE_END,
        TICKET_OPENED,
        TICKET_CLOSED,
        ALL_TICKETS
    ] = useTranslations(i18n)

    const urlParamsToString = useUrlParamsValueToString()

    return (
        <Box className="relative w-full">
            <div className="h-full w-[3px] absolute top-0 left-0 bottom-0" style={{ backgroundColor: props.color }}></div>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                    <Link to={`${props.id}?${urlParamsToString}`}>
                        <h3 title={props.description}>{props.title}</h3>
                    </Link>
                    <MilestoneState {...props} />
                </div>
                <div className="flex gap-2">
                    <Metadata
                        label={DATE_BEGIN}
                        value={(
                            <Chip
                                className="bg-default"
                                label={(
                                    <DateSpan value={props.dateBegin} normalFormat />
                                )}
                            />
                        )}
                    />
                    <Metadata
                        label={DATE_END}
                        value={(
                            <Chip
                                className="bg-default"
                                label={(
                                    <DateSpan value={props.dateEnd} normalFormat />
                                )}
                            />
                        )}
                    />
                </div>
                <div className="flex justify-between">
                    <Metadata
                        label={TICKET_OPENED}
                        value={
                            <Chip
                                className="bg-default px-2 py-0 text-xs"
                                label={props.openTicketsCount as any}
                            />
                        }
                    />
                    <Metadata
                        label={TICKET_CLOSED}
                        value={
                            <Chip
                                className="bg-default px-2 py-0 text-xs"
                                label={props.closedTicketsCount as any}
                            />
                        }
                    />
                    <Metadata
                        label={ALL_TICKETS}
                        value={
                            <div>
                                <Chip
                                    className="bg-default px-2 py-0 text-xs"
                                    label={props.ticketsCount as any}
                                />
                            </div>
                        }
                    />
                </div>
                <div className="w-full h-4 bg-default relative overflow-hidden">
                    <div className="absolute top-0 left-0 bottom-0 bg-primary flex justify-end items-center" style={{ width: `${props.progress}%` }}>
                        <span className="text-xs text-white mx-2">{`${_.round(props.progress as any, 2)}%`}</span>
                    </div>
                </div>
            </div>
        </Box>
    )
}

const i18n = [
    'std_date_begin',
    'std_date_end',
    'Harea.Project.Kanban.Details.Milestone.Tickets.Opened.Number',
    'Harea.Project.Kanban.Details.Milestone.Tickets.Closed.Number',
    'Harea.Project.Kanban.Details.Milestone.Tickets.All.Number',
]

type Props = Partial<TAppointmentMilestone> & {

}
