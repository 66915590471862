import { GenericSuspense, List, useUrlParamsValueToString } from "@mzara/component";
import { KanbanCard } from "../../../components/KanbanCard";
import { KanbanArchivedListQuery } from "../queries/KanbanArchivedListQuery";
import { TAppointment } from "@mzara/graphql";
import { Link, Outlet, useParams } from "react-router-dom";
import { useCallback } from "react";
import { useArchivedListSort } from "../hooks/useArchivedListSort";

export const ProjectKanbanDetailsArchivedContainer = () => {
    const urlParamsValueString = useUrlParamsValueToString()
    const sortItems = useArchivedListSort()
    const { id } = useParams()

    const renderRow = useCallback(
        (row: TAppointment) => (
            <Link className="w-full" to={`${row.num}?${urlParamsValueString}`}>
                <KanbanCard {...row} />
            </Link>
        ),
        [urlParamsValueString]
    );

    return (
        <>
            <List
                BoxProps={{
                    className: "shadow-none px-0",
                }}
                sort={{
                    items: sortItems
                }}
                selection={false}
                GQLRequest={KanbanArchivedListQuery}
                defaultFilter={{
                    key: "",
                    page: 0,
                    pageSize: 50,
                    boardId: parseInt(id),
                    sort: [{ sort: 'DESC', value: 'updatedAt' }]
                }}
                onRenderRow={renderRow}
                isUrlParamsState={false}
            />
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};
