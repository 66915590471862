import { GraphQLQueryClass } from "@mzara/component";
import { TAppointmentsResponse } from "@mzara/graphql";

export class KanbanArchivedListQuery
    implements GraphQLQueryClass<TAppointmentsResponse, TResponse>
{
    queryKey = "App.Harea.Kanban.Board.Detail.Archived.List.Query";

    gql = `
        query KanbanArchivedListQuery($key: String, $page: Float, $pageSize: Float, $boardId: Float) {
            appointments(
                filter: {
                    key: $key
                    page: $page
                    pageSize: $pageSize
                    data: { type: "KANBAN", dateArchive_notIsNull: true, board: { id: $boardId } }
                }
            ) {
                total
                data {
                    id
                    title
                    type
                    num
                    dateBegin
                    hourBegin
                    dateArchive
                    files {
                        id
                    }
                    cover {
                        id
                    }
                    labels {
                        value
                        color
                    }
                    invitations {
                        user {
                            id
                            fullName
                        }
                    }
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TAppointmentsResponse {
        return data.appointments;
    }
}

type TResponse = {
    appointments: TAppointmentsResponse;
};
