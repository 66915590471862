import { Dropdown, IconButton, Menu, MenuItemProps, useDisplayAlert, useTranslation } from "@mzara/component"
import { useCallback, useState } from "react"
import { useKanbanMilestonMenuItems } from "../hooks/useKanbanMilestonMenuItems"
import { useKanbanMilestoneGraphqlMutation } from "../hooks/useKanbanMilestoneGraphqlMutation"
import moment from "moment"
import { useQueryClient } from "react-query"

export const KanbanMilestoneMenu = (props: Props) => {

    const [anchor, setAnchor] = useState<HTMLButtonElement>()
    const menuItems = useKanbanMilestonMenuItems(props.id)
    const mutation = useKanbanMilestoneGraphqlMutation()
    const displayAlert = useDisplayAlert()
    const queryClient = useQueryClient()
    const t = useTranslation()

    const invalidateQueries = useCallback(() => {
        queryClient.invalidateQueries(
            "App.harea.KanbanMilestonesQuery"
        )
        queryClient.invalidateQueries(
            "App.harea.KanbanMilestoneQuery"
        )
    }, [])

    const handleMenuClick = useCallback(async (menu: MenuItemProps) => {
        if (menu.ke === 'copy-link') {
            try {
                await navigator.clipboard.writeText(location.href);
                displayAlert({
                    text: t('std_link_copied'),
                    type: 'success'
                })
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        }

        if (menu.ke === 'open') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateClosed: null
                }
            }, {
                onSuccess: () => {
                    invalidateQueries()
                    props.onUpdated?.()
                }
            })
        }

        if (menu.ke === 'close') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateClosed: moment().format()
                }
            }, {
                onSuccess: () => {
                    invalidateQueries()
                    props.onUpdated?.()
                }
            })
        }

        if (menu.ke === 'archive') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateArchive: moment().format()
                }
            }, {
                onSuccess: () => {
                    invalidateQueries()
                    props.onUpdated?.()
                }
            })
        }

        if (menu.ke === 'unarchive') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateArchive: null
                }
            }, {
                onSuccess: () => {
                    invalidateQueries()
                    props.onUpdated?.()
                }
            })
        }
    }, [])

    return (
        <>
            <IconButton
                className="mr-2"
                icon="fa-solid fa-ellipsis"
                onClick={(e) => setAnchor(e.currentTarget)}
            />
            <Dropdown anchorEl={anchor} onClose={() => setAnchor(undefined)}>
                <Menu
                    onClick={handleMenuClick}
                    items={menuItems}
                />
            </Dropdown>
        </>
    )
}

type Props = {
    id: number
    onUpdated?: () => void
}
