import { AppRouteProps } from "@mzara/component";
import React from "react";
import { DetailContractAboutRoute } from "./views/about/DetailContractAboutRoute";
import { ContractDetailHistoryRoute } from "./views/history/ContractDetailHistoryRoute";
import { DetailContractPaymentRoute } from "./views/payment/DetailContractPaymentRoute";
import { DetailContractWarningsRoutes } from "./views/warnings/DetailContractWarningsRoutes";

const DetailContractContainer = React.lazy(
    () => import("./container/DetailContractContainer")
);

export const DetailContractRoutes: Array<AppRouteProps> = [
    {
        path: "contract/:id",
        title: "{{ContractUserNameFull}}",
        element: <DetailContractContainer />,
        children: [...DetailContractAboutRoute, ...ContractDetailHistoryRoute, ...DetailContractPaymentRoute, ...DetailContractWarningsRoutes],
        roles: ["CONTRACT"],
    },
];
