import { GraphQLQueryClass } from "@mzara/component";
import { TContract } from "@mzara/graphql";

export class ContractPVFilesQuery
    implements GraphQLQueryClass<TContract, TResponse>
{
    queryKey: string;

    gql: string = `
        query($id: Float) {
            contract(filter: { id: $id }) {
                id
                pvFile {
                    id
                    name
                    createdAt
                }
              }
        }
    `;

    variables?: any;

    constructor(variables: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TContract {
        return data.contract;
    }
}

type TResponse = {
    contract: TContract;
};
