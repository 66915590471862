import { Box, GenericSuspense, IconButton, Menu, RightSidebar, useTranslationRoute, useTranslations } from "@mzara/component"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import { useMindMapDetailsViewerMenu } from "view/designer/views/viewer/hooks/useMindMapDetailsViewerMenu"

export const MindMapViewerContainer = () => {

    const [
        PARAMS
    ] = useTranslations(i18n)
    const { id } = useParams()
    const [, , , , , , , tab] = location.pathname.split("/");
    const menus = useMindMapDetailsViewerMenu(parseInt(id))
    const navigate = useNavigate()

    useEffect(() => {

        if (!menus.some((item) => item.ke === tab)) {
            navigate(menus[0].link, { replace: true })
        }
    }, [tab])
    const tr = useTranslationRoute()

    return (
        <RightSidebar className="right-sidebar-absolute bg-white pb-8 shadow-md !z-[60]">
            <div>
                <Box title={PARAMS} className="box-transparent shadow-none px-0">
                    <Link className="absolute top-0 right-0" to={tr(`admin/mind-map/${id}/designer`)}>
                        <IconButton icon="fa-times" />
                    </Link>
                </Box>
                <div>
                    <Box className='box-transparent shadow-none box-p-0 sticky-top overflow-x-auto overlow-y-hidden !p-0'>
                        <Menu type="HORIZONTAL" className='menu-tabs' items={menus} activeFn={(menu) => menu.ke === tab} />
                    </Box>
                    <GenericSuspense>
                        <Outlet />
                    </GenericSuspense>
                </div>
            </div>
        </RightSidebar>
    )
}

const i18n = [
    'std_params'
]

