import { Box, DateSpan, FileItem, useFileUrl, useTranslation } from "@mzara/component";
import { useContractPVFilesQuery } from "../../warnings/hooks/useContractPVFilesQuery";

const ContractPVControl = ({ id }: { id?: number }) => {
    const { data } = useContractPVFilesQuery(id);
    const t = useTranslation()


    const fileUrl = useFileUrl();

    return (
        <Box
            title={t(
                "Harea.Contract.Details.About.Confirmed.PVFile.Title"
            )}
        >

            <div className="flex flex-col gap-4">
                <div className="mt-4 flex gap-4 flex-wrap">
                    {
                        data?.pvFile &&
                        <FileItem
                            {...data?.pvFile}
                            endIcon={(
                                <a href={fileUrl(data?.pvFile.id)} download={true}>
                                    <i className="fas fa-download"></i>
                                </a>
                            )}
                        />
                    }

                </div>
                {
                    !data?.pvFile &&
                    <div className="mx-auto">
                        <p>
                            {t("Harea.Contract.Details.About.Confirmed.PVFile.NoFile.Message")}
                        </p>
                    </div>
                }
            </div>
        </Box >
    );
};

export { ContractPVControl };
