import { useAjax, useDownloadBrowser, useMzMutation } from "@mzara/component";

export const useDownloadWarningFile = () => {
    const ajax = useAjax();
    const download = useDownloadBrowser();
    return useMzMutation(
        (id: number) =>
            ajax.send({
                type: "GET",
                url: `${process.env.REACT_APP_API_ENDPT}contract/warning/template/document/${id}`,
                data_type: "application/octet-stream",
            }),
        {
            onSuccess: (data) => {
                const blob = new Blob([data.response]);
                download(blob, `warning_template.docx`);
            },
        }
    );
};
