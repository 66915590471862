import { useGraphqlQuery } from "@mzara/component";
import { TContract } from "@mzara/graphql";
import { ContractPVFilesQuery } from "../queries/ContractPVFilesQuery";

export const useContractPVFilesQuery = (contractId: number) => {
    return useGraphqlQuery<ContractPVFilesQuery, TContract>(
        new ContractPVFilesQuery({ id: contractId }),
        { suspense: !!contractId, enabled: !!contractId }
    );
};
