import { AppRouteProps } from "@mzara/component";
import React from "react";
import { DetailContractWarningsContainer } from "./containers/DetailContractWarningsContainer";


export const DetailContractWarningsRoutes = [
    {
        path: "warning",
        element: <DetailContractWarningsContainer />,
    },
] as Array<AppRouteProps>;
