import { GraphQLQueryClass } from "@mzara/component";
import { TUserOrganisation, TUserOrganisationProfile } from "@mzara/graphql";

export class OrganisationWorkHourQuery
    implements GraphQLQueryClass<TUserOrganisationProfile, TResponse>
{
    queryKey: string = "App.Harea.Organisation.Work.Hour.Query";

    gql: string = `
        query OrganisationWorkHourQuery($id: Float) {
            userOrganisationProfile(filter: { organisation: { id: $id } }) {
                id
                isEditable
                workHourBegin
                workHourEnd
                workRestHourBegin
                workRestHourEnd
                canUpdateWorkTime
            }
        }
    `;

    variables?: any;

    constructor(variables: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TUserOrganisationProfile {
        return data.userOrganisationProfile;
    }
}

type TResponse = {
    userOrganisationProfile: TUserOrganisationProfile;
};
