import { ChipMenu, MenuItemProps, useListFilterState, useTranslations } from "@mzara/component"
import { useMilestoneStateFilters } from "../hooks/useMilestoneStateFilters"
import { MILESTONE_LIST_ID } from "../container/ProjectKanbanDetailsMilestoneListContainer";
import { useCallback, useEffect, useMemo } from "react";

export const MilestoneQuickFilters = () => {

    const [
        OPENED
    ] = useTranslations(i18n)
    const { filter, setFilter } = useListFilterState(MILESTONE_LIST_ID);
    const stateItems = useMilestoneStateFilters()

    

    const stateValue = useMemo(() => {

        if (filter?.data?.dateClosed_notIsNull) {
            return 'CLOSED'
        }
        if (filter?.data?.dateArchive_notIsNull) {
            return 'ARCHIVED'
        }

        if (filter?.data?.dateArchive_isNull && filter?.data?.dateClosed_isNull) {
            return 'OPENED'
        }

        return 'ALL'
    }, [filter])
    
    const handleStateFilterClick = useCallback((menu: MenuItemProps) => {

        let filterData: Record<string, boolean> = {
            dateArchive_isNull: undefined,
            dateClosed_isNull: undefined,
            dateArchive_notIsNull: undefined,
            dateClosed_notIsNull: undefined
        }
        if (menu.value === 'OPENED') {
            filterData.dateArchive_isNull = true
            filterData.dateClosed_isNull = true
        }
        if (menu.value === 'CLOSED') {
            filterData.dateClosed_notIsNull = true
        }
        if (menu.value === 'ARCHIVED') {
            filterData.dateArchive_notIsNull = true
        }

        setFilter((v: any) => ({
            ...v,
            data: {
                ...v.data,
                ...filterData
            },
        }));
    }, [setFilter])

    return (
        <div className="flex items-center gap-2">
        
            <ChipMenu
                items={stateItems}
                defaultLabel={OPENED}
                value={stateValue}
                onClick={handleStateFilterClick}
            />
        </div>
    )
}

type Props = { 

}

const i18n = [
    'std_opened'
]
