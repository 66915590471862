import { Box, Button, ControlList, Dialog, FileItem, useFileUrl, useTranslation } from "@mzara/component";
import { useContractWarningFilesQuery } from "../hooks/useContractWarningFilesQuery";
import { useContractDetailQuery } from "views/detail/hooks/useContractDetailQuery";
import { useState } from "react";
import { useContractWarningFilesForm } from "../hooks/useContractWarningFilesForm";

const ContractWarningControl = ({ id }: { id?: number }) => {

    const { data } = useContractDetailQuery(id, true);
    const { data: dataWarning, invalidateQuery } = useContractWarningFilesQuery(id);
    const fileUrl = useFileUrl();
    const t = useTranslation()
    const [add, setAdd] = useState(false)
    const control = useContractWarningFilesForm()

    const handleSubmited = () => {
        invalidateQuery()
        setAdd(false)
    }

    return (
        <Box
            title={t(
                "Harea.Contract.Details.About.Confirmed.WarningBox.Title"
            )}
            tools={(
                <>
                    {
                        data.isEditable &&
                        <Button
                            label={t('std_add')}
                            startIcon="fa-plus"
                            className="btn-primary"
                            onClick={() => setAdd(true)}
                        />
                    }
                </>
            )}
        >


            {
                dataWarning.warningFiles?.length > 0 &&
                <div className="flex flex-col gap-4">
                    <div className="mt-4 flex gap-4 flex-wrap">
                        
                        {
                            dataWarning.warningFiles?.map((file) => (
                                <FileItem
                                    key={file.id}
                                    {...file}
                                    endIcon={(
                                        <a href={fileUrl(file.id)} download={true}>
                                            <i className="fas fa-download"></i>
                                        </a>
                                    )}
                                />
                            ))
                        }
                    </div>
                    <div className="mx-auto">
                        <p>
                            {t("Harea.Contract.Details.About.Confirmed.Dismissal.Tooltip.Text.Warning")}
                        </p>
                    </div>
                </div>
            }
            {
                dataWarning.warningFiles?.length === 0 &&
                <div className="mx-auto">
                    <p>
                        {t("Harea.Contract.Details.About.Confirmed.WarningBox.NoWarnings.Message")}
                    </p>
                </div>
            }

            <Dialog
                open={add}
                onClose={() => setAdd(false)}
            >
                <ControlList
                    {...control}
                    defaultValue={{
                        id: id,
                        warningFiles: null
                    }}
                    onSubmited={handleSubmited}
                    onCancel={() => setAdd(false)}
                    onBeforeSaving={(value) => {
                        return { ...value, warningFiles: [...(dataWarning.warningFiles ?? []), { id: value.warningFiles?.id }] }
                    }}
                />
            </Dialog>
        </Box>
    );
};

export { ContractWarningControl };
