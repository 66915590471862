import { useTranslationRoute, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuAdminManagement = () => {

    const [
        ADMIN_MANAGEMENT,
        PAYSLIP,
        CONTRACTS,
        ABSCENSE,
        PORTFOLIO,
        SOCIAL_STATEMENT
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();

    return useMemo(() => ({
        ke: "general",
        label: ADMIN_MANAGEMENT,
        className: "menu-item-category",
        items: [
            {
                label: CONTRACTS,
                startIcon: "fa-solid fa-person",
                link: translationRoute("admin/contract"),
                roles: ["CONTRACT"],
            },
            {
                label: PAYSLIP,
                startIcon: "fa-solid fa-clipboard-list",
                link: translationRoute("admin/payslips"),
                roles: ["PAYING_SLIP"],
            },
            {
                label: ABSCENSE,
                startIcon: "fa-solid fa-person-skating",
                link: translationRoute("admin/project/dayoff"),
                roles: ["CONTRACT"],
            },
            {
                label: SOCIAL_STATEMENT,
                link: translationRoute("admin/payslips/social-statement"),
                roles: ["PAYING_SLIP_SOCIALS"],
                startIcon: "fa-solid fa-file",
            },
            {
                label: PORTFOLIO,
                startIcon: "fa-solid fa-briefcase",
                link: translationRoute("admin/portfolio"),
                roles: ["PORTFOLIO"],
            },
        ],
    }), [])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Payslip.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Contracts.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Abscense.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Portfolio.Label',
    "Harea.Payslip.Menu.Label.Social.Statement",
]
