import { GraphQLQueryClass } from "@mzara/component";
import { TAppointmentBoard, TUser } from "@mzara/graphql";

export class AppointmentEventUsersQuery implements GraphQLQueryClass<Array<TUser>, TResponse> {
    queryKey: string = "Harea.AppointmentEventUsersQuery.Query";

    gql: string = `
        query AppointmentEventUsersQuery {
            users (filter:{ sort: [{ sort: "ASC", value: "fullName" }] }) {
                data{
                    id fullName profilePicture { id }
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): Array<TUser> {
        return data.users.data;
    }
}

type TResponse = {
    users: {
        data: Array<TUser>;
    }
};
